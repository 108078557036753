import Footer from '@/components/footer';
import Header from '@/components/header';
import HeadC from '@/ctf-components/headc/headc';
import NavigationButton from '@/ctf-components/navigationButton';
import Section from '@/ctf-components/section/section';
import {
  fetchFooterData,
  fetchHeaderMainMenuNavigation,
  fetchHomePageData,
  useHomeData
} from '@/hooks';
import { SectionIC } from '@/lib/interface-types';
import { QueryClient, dehydrate } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import uuid from 'react-uuid';
import HeroBanner from '../components/page-hero-banner';

const Home = () => {
  const { locale } = useRouter();
  const { data, isLoading } = useHomeData(locale);

  const {
    sectionsCollection,
    pageName,
    titleDescription,
    containerForCustomButton,
    slug,
    seo
  } = data?.homePageCollection?.items[0] || {};

  if (isLoading) return <div>Loading</div>;

  return (
    <>
      <HeadC seo={seo} />
      <Header />
      <main className="container mx-auto">
        <HeroBanner title={pageName} coloredTitle richText={titleDescription} />
        <div>
          {sectionsCollection?.items &&
            sectionsCollection.items.map((section: SectionIC) => {
              return <Section slug={slug} key={uuid()} section={section} />;
            })}
        </div>
        <div className="CustomButton mt-20">
          <NavigationButton data={containerForCustomButton} />
        </div>
      </main>
      <Footer />
    </>
  );
};

export async function getStaticProps({ locale }: { locale: string }) {
  const queryClient = new QueryClient();
  await queryClient.prefetchQuery({
    queryKey: ['home', locale],
    queryFn: () => fetchHomePageData(locale)
  });
  await queryClient.prefetchQuery({
    queryKey: ['footer', locale],
    queryFn: () => fetchFooterData(locale)
  });
  await queryClient.prefetchQuery({
    queryKey: ['headermainmenu', locale],
    queryFn: () => fetchHeaderMainMenuNavigation(locale)
  });

  return {
    props: {
      dehydratedState: dehydrate(queryClient)
    },
    revalidate: 10
  };
}

export default Home;
