import HeaderCtf from '@/ctf-components/header';
import HeaderMobile from '@/ctf-components/header/mobile-header';

const Header = () => {
  return (
    <header className="sticky top-0 bg-white z-50 container mx-auto py-3 md:py-6 ">
      <div className="HeaderNavigation flex justify-between items-center max-[1150px]:hidden">
        <HeaderCtf />
      </div>
      <div className="flex min-[1151px]:hidden">
        <HeaderMobile />
      </div>
    </header>
  );
};

export default Header;
